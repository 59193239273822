import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as RepeatIcon} from '../../assets/repeat-icon.svg';
import { AppDispatch, RootState } from '../../app/store';
import { selectCurrentNamespaceId } from '../../features/namespace/namespaceSlice';
import { selectWorkspaceDocuments, Document } from '../../features/document/documentSlice';
import { selectCountNonCompletedDocumentsByIds } from '../../features/document/documentProcessingStatusSlice';
import { selectDocumentIdsByTemplateId, fetchTemplateDocuments } from '../../features/document/documentSlice';
import { setMemoPageForWorkspace } from '../../features/workspace/workspaceSlice';
import { fetchWorkspaceMemos, selectMostRecentMemoByWorkspaceId, selectMemoCountByWorkspaceId, createWorkspaceMemo } from '../../features/workspace-memo/workspaceMemoSlice';
import { fetchWorkspaceMemoSections } from '../../features/workspace-memo-section/workspaceMemoSectionSlice';
import { selectUser } from '../../features/user/userSlice'; 
import MemoTemplatePicker from '../MemoTemplatePicker';

const MemoGenHeader = () => {
    const dispatch: AppDispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { id: workspaceId = "" } = useParams();
    const totalPages = useSelector(selectMemoCountByWorkspaceId(workspaceId));
    const memo = useSelector(selectMostRecentMemoByWorkspaceId(workspaceId));
    const currentNamespaceId = useSelector(selectCurrentNamespaceId);
    const [selectedTemplateId, setCurrentTemplateId] = useState<string | undefined>(memo?.template_id);
    const templateDocumentIds = useSelector(selectDocumentIdsByTemplateId(selectedTemplateId || ''));
    const workspaceDocuments = useSelector(selectWorkspaceDocuments(workspaceId));
    const workspaceDocIds = workspaceDocuments.map((doc: Document) => doc.id);
    const user = useSelector(selectUser);

    const validDocument = workspaceDocuments.length > 0;
    const nonCompletedDocsCount = useSelector((state: RootState) => selectCountNonCompletedDocumentsByIds(state, workspaceDocIds));
    const unCompletedTemplateDocsCount = useSelector((state: RootState) => selectCountNonCompletedDocumentsByIds(state, templateDocumentIds));

    const shouldShowApproveButton = user?.config?.can_approve_memo;
    // const shouldShowInReview = !memo?.config?.approved && totalPages === 1 && !shouldShowApproveButton;
    const shouldShowInReview = false;
    
    useEffect(() => {
        if (workspaceId) {
            dispatch(fetchWorkspaceMemos({ workspaceId }));
            setCurrentTemplateId(memo?.template_id);
        }
    }, [dispatch, workspaceId, memo?.template_id]);

    useEffect(() => {
        const memoId = memo?.id;
        if(memoId) {
            dispatch(fetchWorkspaceMemoSections({ workspaceId, workspaceMemoId: memoId }));
        }
    }, [dispatch, workspaceId, memo?.id]);

    useEffect(() => {
        if(selectedTemplateId && currentNamespaceId) {
            dispatch(fetchTemplateDocuments({ namespaceId: currentNamespaceId, templateId: selectedTemplateId }));
        }
    }, [dispatch, selectedTemplateId, currentNamespaceId]);

    const onRepeatClick = async () => {
        if (workspaceId && selectedTemplateId) {
            await dispatch(createWorkspaceMemo({ workspaceId, templateId: selectedTemplateId }));
            dispatch(fetchWorkspaceMemos({ workspaceId }));

            if(totalPages){
                const query = new URLSearchParams(location.search);
                const nextPage = totalPages + 1;
                query.set('page', nextPage.toString());
                dispatch(setMemoPageForWorkspace({ workspaceId, pageNumber: nextPage }));
                navigate(`?${query.toString()}`);
            }
        }
    }
    const templatesDocsReady = selectedTemplateId && templateDocumentIds.length > 0 && unCompletedTemplateDocsCount === 0;
    const canRepeat = nonCompletedDocsCount === 0 && selectedTemplateId && validDocument && (workspaceId !== 'new' || memo?.draft_processing_status === 'COMPLETED') && templatesDocsReady && !shouldShowInReview;
    const memoName = 'Draft Report';
    let memoTitleContent = <div className='flex flex-row items-center'> 
        {memoName}
        <button 
            className={`ml-2 h-7 w-7 text-gray-500 rounded-full bg-dark-purple-800 flex justify-center items-center ${!canRepeat ? 'cursor-not-allowed opacity-25' : 'cursor-pointer'}`}
            disabled={!canRepeat}
            onClick={onRepeatClick}
            >
            <RepeatIcon className='h-5 fill-white'/>
        </button>
    </div>

    const memoHeaderContent = <div className='flex flex-row w-full'>
        <div className='flex flex-col w-full justify-center'>
            <div className='flex flex-row items-center'> 
                {memoTitleContent}
            </div>
        </div>
        <div className='w-72'>
            <MemoTemplatePicker workspaceId={workspaceId} onSelect={(templateId: string) => { setCurrentTemplateId(templateId) }} />
        </div>
    </div>

    return (
        <div className='flex flex-row justify-between items-center p-4 px-6 mr-10 bg-brown-grey-500 rounded-xl w-full'>
            <div className='flex flex-col w-full relative'>
                <div className='font-semibold w-full'>
                    {memoHeaderContent}
                </div>
            </div>
        </div>
    );
}

export default MemoGenHeader;